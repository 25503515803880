import React from 'react'

function Footer() {
  return (
    <>
      <div className='footer'>
        <p className='footertxt'>
          Developed for Fun By <a href='http://rijan.info.np' target='_blank' rel='noreferrer'>Rijan Neupane</a> 😂
        </p>
      </div>
    </>
  )
}

export default Footer